<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <a-button :disabled="!isEdit" @click="handleExport">入库单</a-button>
        <a-divider type="vertical" />
        <a-button>
          <router-link :to="{ name: 'Purchase' }">取消</router-link>
        </a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="入库单号">
              <a-input placeholder="入库单号自动生成" disabled v-model="form.purchase_num" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="仓库" prop="warehouse_id">
              <a-select placeholder="选择仓库" ref="warehouse" showSearch v-model="form.warehouse_id">
                <a-select-option v-for="d in warehouseOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="供应商" prop="supplier_id">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                placeholder="选择供应商"
                v-model="form.supplier_id"
              >
                <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库时间">
              <a-date-picker v-model="form.purchase_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" />
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <a-card class="card" title="其它信息" :bordered="false">
      <a-tabs
        :activeKey="activeKey"
        @change="
          key => {
            activeKey = key
          }
        "
      >
        <a-tab-pane tab="货物信息" key="1">
          <edit-table
            :columns="goodsColumns"
            :sourceData="goodsData"
            :scroll="true"
            @getTableDate="getGoodsData"
          ></edit-table>
        </a-tab-pane>
        <template slot="tabBarExtraContent">
          <span style="margin-right: 50px;">进货总价：{{ price_in_total.toFixed(2) }}</span>
        </template>
      </a-tabs>
    </a-card>
  </div>
</template>
<script>
import { getWarehouseList, createPurchase, getPurchaseDetail, getGoodInfoList } from '@/api/wms/sale'
import moment from 'moment'
import { getRandomCode, getFirstLetter } from '@/utils/util'
import { EditTable } from '@/components'
import preventReClick from '@/utils/preventReClick'
import { getSupplier, downloadExcel } from '@/api/common'
import { exportWmsPurchase } from '@/api/wms'

export default {
  name: 'PurchaseCur',
  components: {
    EditTable,
    preventReClick
  },
  data() {
    return {
      confirmLoading: false,
      isEdit: false,
      activeKey: '1',
      form: {
        purchase_num: '',
        warehouse_id: null,
        supplier_id: null,
        remark: ''
      },
      rules: {
        warehouse_id: [{ required: true, message: '请选择仓库', trigger: 'blur' }],
        supplier_id: [{ required: true, message: '请选择供应商', trigger: 'blur' }]
      },
      warehouseOps: [], // 仓库
      supplierOps: [], // 供应商
      goodsData: [],
      goodInfoOps: [],
      feeData: [],
      goodNoOps: [],
      goodInfoList: [],
      goodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          width: 150,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.storage_status === 1 || record.status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null;
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item;
                    }
                  }
                  this.goodsData[index]['good_id'] = value.id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  const data = [];
                  // 动态为 进货单价改变数据结构为 key: value 的形式
                  value.price_in.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[7].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  // this.goodsData[index]['unit_price'] = value.price_in;
                  this.goodsData[index]['price_out'] = value.price_out;
                }}
              >
                {this.goodNoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          width: 500,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                disabled={record.storage_status === 1 || record.status === 1}
                filterOption={this.filterOption}
                onChange={val => {
                  let value = null
                  for (const item of this.goodInfoList) {
                    if (item.id === val) {
                      value = item
                    }
                  }
                  this.goodsData[index]['good_id'] = value.id;
                  this.goodsData[index]['good_no'] = value.good_no;
                  this.goodsData[index]['good_name'] = value.good_name;
                  this.goodsData[index]['good_unit'] = value.good_unit;
                  this.goodsData[index]['good_spec'] = value.good_spec;
                  const data = [];
                  // 动态为 进货单价改变数据结构为 key: value 的形式
                  value.price_in.forEach(v => {
                    data.push({ name: v, value: v });
                  });
                  this.goodsColumns[7].extra.options = data;
                  this.goodsColumns = [...this.goodsColumns];
                  // this.goodsData[index]['unit_price'] = value.price_in;
                  this.goodsData[index]['price_out'] = value.price_out;
                }}
              >
                {this.goodInfoOps.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          width: 80,
          extra: {
            type: 'text'
          }
        },
        {
          title: '生产日期',
          dataIndex: 'product_date',
          width: 140,
          extra: {
            default: null
          }
        },
        {
          title: '生产批次',
          dataIndex: 'product_batch',
          width: 140,
          extra: {
            default: null
          }
        },
        {
          title: '保质期',
          dataIndex: 'quality_days',
          width: 80,
          extra: {
            type: 'number',
            default: null
          }
        },
        {
          title: '采购单价',
          dataIndex: 'unit_price',
          width: 100,
          extra: {
            type: 'select',
            options: [],
            extraFunc: this.calcTotal,
            disabled: record => {
              return record.storage_status === 1 || record.status === 1
            }
          }
        },
        {
          title: '采购数量',
          dataIndex: 'num',
          width: 100,
          extra: {
            type: 'number',
            extraFunc: this.calcTotal,
            disabled: record => {
              return record.storage_status === 1 || record.status === 1
            }
          }
        },
        {
          title: '总价',
          dataIndex: 'total',
          width: 100,
          extra: {
            type: 'text'
          }
        },
        {
          title: '进货备注',
          dataIndex: 'remark',
          width: 180,
          extra: {
            disabled: record => {
              return record.storage_status === 1 || record.status === 1
            }
          }
        }
      ],
      price_in_total: 0
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'PurchaseCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false
          this.$route.meta.title = '新增进货信息'
        } else {
          this.isEdit = true
          this.$route.meta.title = '编辑进货信息'
          this.getPurchaseInfo(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.isEdit = true
      this.$route.meta.title = '编辑进货信息'
      this.getPurchaseInfo(this.$route.params.id)
    }
  },
  created() {
    this.handleFormReset()
    getWarehouseList().then(v => {
      this.warehouseOps = v
    })
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
    getGoodInfoList().then(res => {
      this.goodInfoList = res
      this.goodInfoOps = res.map(e => {
        return {
          name: `${e.good_name}/${e.good_unit}/${e.good_spec}`,
          value: e.id
        }
      });
      for (let i = 0; i < res.length; i++) {
        if (res[i].good_no && res[i].good_no !== '') {
          this.goodNoOps.push({
            name: res[i].good_no,
            value: res[i].id
          });
        }
      }
    })
  },
  methods: {
    moment,
    getRandomCode,
    // 入库单导出功能
    handleExport() {
      exportWmsPurchase({ id: this.form.id }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = '';
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    },
    handleFormReset() {
      this.form = {
        purchase_num: '',
        warehouse_id: null,
        supplier_id: null,
        remark: ''
      }
      this.goodsData = []
      this.feeData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      });
      this.price_in_total = 0;
    },
    getPurchaseInfo(id) {
      getPurchaseDetail({
        id: id
      }).then(res => {
        res.purchase.purchase_date = moment(res.purchase.purchase_date)
        for (const item of res.good_in_list) {
          if (item.status === 1) {
            item.disabled = true
          }
        }
        this.form = res.purchase;
        this.goodsData = res.good_in_list;
        // 重新计算进货总价前将进货总价置为空
        this.price_in_total = 0;
        this.goodsData.forEach(v => {
          this.price_in_total += parseFloat(v.total);
        });
      })
    },
    getGoodsData(data) {
      this.goodsData = data;
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.total);
      });
      this.price_in_total = money;
    },
    getFeeData(data) {
      this.feeData = data
    },
    calcTotal(value, newData, target) {
      if (target.unit_price && target.num) {
        target['total'] = (target.unit_price * target.num).toFixed(2)
      }
      let money = 0;
      this.goodsData.forEach(v => {
        money += parseFloat(v.total);
      });
      this.price_in_total = money;
      return newData
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSubmit() {
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = false
          for (const item of this.goodsData) {
            if (item.product_date) {
              item.product_date = moment(item.product_date).valueOf();
            }
            if (item.storage_status === undefined) {
              item.storage_status = 0; // 未入库的货物
            }
          }
          const purchaseInfo = {
            purchase: {
              ...this.form,
              purchase_date: this.form.purchase_date ? moment(this.form.purchase_date).valueOf() : null
            },
            good_in_list: this.goodsData
          }
          createPurchase(purchaseInfo).then(v => {
            this.formVisible = false
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            // this.$router.push({
            //   name: 'Purchase',
            //   query: {
            //     isEdit: this.isEdit
            //   }
            // })
            this.getPurchaseInfo(v);
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}
</style>
